//  ces加密
import CryptoJS from 'crypto-js'
//加密


export function encrypt(code, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(code, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}


// AES加密
export function encryptAES(code, key) {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(code, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}

//AES解密
export function decryptAES(encyptedCode, key) {
	const keyHex = CryptoJS.enc.Utf8.parse(key);
	const decrypted = CryptoJS.AES.decrypt({
		ciphertext: CryptoJS.enc.Base64.parse(encyptedCode)
	}, keyHex, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	});
	return decrypted.toString(CryptoJS.enc.Utf8);
}
