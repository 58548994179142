import React, { useReducer } from "react";
import { createCookieString, getData, getDataWithToken, postData, putData, putDataWithToken, to } from "./utils";
import requestUrl from "./requests";
import { NewUser } from "./types";
import { message } from "antd";
import { encryptAES } from "../utils/crypto";

const defaultKey = 'c32a193shy2gz48l';

export interface User {
  user_id: number;
  isAuthed: boolean;
  token: string
  official_open_id?: string
}

interface Props {
  login: any;
  logout: any;
  user: User;
  registerWithQrCode: Function,
  createUserAndLogin: Function,
  bindUserAndLogin: Function,
  changePassword: Function,
  changeEmail: Function,
  forgetPassword: Function,
  changeforgetPassword: Function,
  getVideoSig: Function


}

interface ReducerAction {
  type: string;
  data?: any;
}


interface qrLoginResult {
  focus_status: boolean,
  official_open_id?: string,
  token?: string
}

//将用户保存到本地存储
const rememberUser = (data: object, exdays: number) => {
  let dataString = createCookieString(data);
  let d = new Date();
  d.setTime(d.getTime() + (exdays === -1 ? -1000 : exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();

  let cookieString = `${dataString};${expires};`;
  
  document.cookie = cookieString;
}

export const deleteCookieUser = () => {
  const data = { token: "", user_id: "" }
  rememberUser(data, -1);
}

const getCookieUser = () => {
  let cookieItemArr = document.cookie.split(';');
  let [tokenItem = undefined] = cookieItemArr.filter((ci: string) => ci.includes('token='))
  if (tokenItem) {

    let u = { isAuthed: true, user_id: "", token: "" };
    let ca = tokenItem.split('&');


    for (let i in ca) {

      let pair = ca[i].split("=");
      if (pair[0] === 'token') {
        u.token = pair[1];
      }

      if (pair[0] === 'user_id') {
        u.user_id = pair[1];
      }

    }

    return u;
  } else {
    return { isAuthed: false, user_id: null, token: "" }
  }


}

const calculateRoute = (qrResult: qrLoginResult, loginType: number) => {
  console.log(qrResult);
  if (loginType === 1) {
    //扫码登录
    if (qrResult.token) {
      rememberUser(qrResult, 3);
      console.log(1, qrResult);

      return "/home"
    } else {
      return "/user"
    }
  } else {
    //扫码注册
    //有token代表已经注册，反之去注册
    if (qrResult.token) {
      rememberUser(qrResult, 3);
      console.log(2, qrResult);
      return "/home"
    } else {
      return "/user"
    }
  }
}

//用户状态需要全局维护,所以需要createContext,公司数据同理。
export const UserContext = React.createContext<Partial<Props>>({});


export function useUser() {
  const initialState = getCookieUser();

  let [user, dispatch] = useReducer((state: User, action: ReducerAction) => {
    switch (action.type) {
      case 'GET_USER':
        return { ...state, ...action.data };
      case 'LOG_IN': {

        return { ...state, isAuthed: true, ...action.data };
      }
      case 'LOG_OUT': {

        return { ...state, isAuthed: false, ...action.data };
      }

      case 'REGISTER': {

        return { ...state, isAuthed: true, ...action.data };
      }


      default:
        return state;
    }
  }, initialState);

  async function createUserAndLogin(data: NewUser, cb: Function, onError: Function) {
    let [error, res] = await to(postData(requestUrl.register, data));

    if (error) {
      onError();
      return;
    }

    let user = { ...res.data }

    rememberUser(user, 3);

    dispatch({ type: 'LOG_IN', data: res.data });

    if (cb) {
      cb();
    }

  }

  async function bindUserAndLogin(data: any, cb: Function, onError: Function) {
    data.postfix = '_qzoff'
    let [error, res] = await to(putData(requestUrl.binduser, data));

    if (error) {
      onError();
      return;
    }

    let user = { ...res.data }

    rememberUser(user, 3);

    dispatch({ type: 'LOG_IN', data: res.data });

    if (cb) {
      cb();
    }

  }


  async function registerWithQrCode(id: number | string, loginType: number, skip: Function) {

    let res = await getData(requestUrl.loginWithQr, { qr_code_url_id: id });
    // console.log(1,res);
    if (res.data.official_open_id) {
      dispatch({ type: 'LOG_IN', data: res.data });

      let route = calculateRoute(res.data, loginType);


      if (route) {
        console.log(222, route);

        //console.log('11');
        //rememberUser(res.data,5);
        skip(route);
      }
    }
  }

  async function login(loginData: { username: string; password: string }, cb?: Function) {
    // console.log(JSON.stringify(loginData))
    // loginData = {
    //   "username": "807527765@qq.com_super",
    //   "password": "Jialei@2023"
    // }
    let encryptedData = encryptAES(JSON.stringify(loginData),defaultKey)
    
    // console.log(encryptedData);
    
    let [error, res] = await to(postData(requestUrl.login, {data:encryptedData}));

    if (!error) {
      rememberUser(res.data, 5);
      dispatch({ type: 'LOG_IN', data: res.data });
      if (cb) {
        cb();
      }
    }

  }

  async function getUserInfo() {
    const url = requestUrl.user.replace('{user_id}', user.user_id);
    let [err, res] = await to(getDataWithToken(url));
    if (!err) {
      dispatch({ type: 'LOG_IN', data: res.data });
    }
  }


  async function changePassword(data: any) {
    const url = requestUrl.user.replace('{user_id}', user.user_id);
    await putDataWithToken(`${url}password/`, data);
    deleteCookieUser();
    dispatch({ type: 'LOG_OUT' });

  }

  async function changeEmail(data: any) {
    const url = requestUrl.userProfile.replace('{user_id}', user.user_id);
    await putDataWithToken(url, data);
    deleteCookieUser();
    dispatch({ type: 'LOG_OUT' });

  }

  async function forgetPassword(data: any) {
    const url = requestUrl.register;
    let [err] = await to(postData(`${url}forget_password/`, data));
    if (!err) message.success('修改密码链接已发送至您的邮箱');
  }

  async function changeforgetPassword(data: any, token: string) {
    const url = requestUrl.register;
    await putData(`${url}forget_password/${token}/`, data);
    message.success('密码修改成功');

  }

  async function getVideoSig() {
    const url = requestUrl.user.replace('{user_id}', user.user_id);
    return to(getDataWithToken(`${url}required_information/`));
  }


  async function logout() {
    //let [error,res] = await to(postData(requestUrl.login,loginData))
    deleteCookieUser();
    dispatch({ type: 'LOG_OUT' });


  }


  return {
    user, login, registerWithQrCode, createUserAndLogin, bindUserAndLogin, logout,
    changePassword, changeEmail, forgetPassword, changeforgetPassword, getVideoSig, getUserInfo
  };

}