import React from 'react';
import "./style.less";

function Footer() {

  return (
      <div className="yph-footer">
          <div className="yph-footer-contact">
            {/* <div>联系客服：198 5700 5568</div> */}
            {/* <div>联系客服</div>
            <img style={{width:100,height:100}} src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGI8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAybTJUdUlKMk9lY0QxMDAwMGcwN0IAAgQpiARjAwQAAAAA" alt="" /> */}
          </div>
          <div className="yph-footer-copy">Copyright © 2023 WWW.SSBJRCW.COM All Rights Reserved <a style={{color:'inherit'}} target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"> 浙ICP备2023016545号</a></div>
      </div>
    
  );
}

export default Footer;