import mainLogo from '../assets/images/mainLogo.png';
import logoWhite from '../assets/images/onlyLogoWhite.png';
import logoBlack from '../assets/images/logoBlack.png';
import companyCard from "../assets/images/companyCard.png"


export const images = {
    mainLogo,
    logoBlack,
    logoWhite,
    companyCard,
}