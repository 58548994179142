import React, { useContext, useEffect } from 'react';
import { Button, Form, Input, Tabs } from "antd";
import { UserContext } from "../../models/user";
import { Link, useHistory } from "react-router-dom";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import LoginQr from '../../components/loginQr';
import { images } from "../../utils/imgConfig";
import Footer from '../../components/footer/footer';
import { getTokenInCookie } from '../../models/utils';
import './style.less';



const {TabPane} = Tabs;

function App() {
  let {login, user} = useContext(UserContext);
  let history = useHistory();

  //登录成功，进入主页
  const gotoCompany = () => {

    history.push('/home');
  }

  const onFinish = (values: any) => {
    
    login(values, gotoCompany);
  }
  const gotoApp = (url: string) => {
    history.push(url);
  }
  let token = getTokenInCookie();

  useEffect(() => {
    if (user?.isAuthed && token) {
      history.push('/home');
    }
  }, []);


  return (

    <div className="yph-login-page yph-public-bg-page">
      <div className="yph-login-page-head">

      </div>
      <div className="yph-login-page-content">
        <div className="yph-login-page-content-title">
          <img src={images.mainLogo} style={{height: '54px', verticalAlign: "top"}} alt="logo"/>
          <span className="yph-login-page-content-title-main">四省边际人才网</span>
        </div>
        <div className="yph-login-page-content-description">企业管理后台</div>
        <div className="yph-login-page-content-form">
          <Tabs centered defaultActiveKey="1">
            <TabPane tab="账号密码登录" key="1">
              <div>
                <Form
                  onFinish={onFinish}
                >
                  <Form.Item
                    label=""
                    name="username"
                    rules={[{required: true, message: '请输入用户名!'}]}
                  >
                    <Input placeholder="账号/邮箱 登录" prefix={<UserOutlined style={{color: 'var(--primary-color)'}}/>}
                           size="large"/>
                  </Form.Item>

                  <Form.Item
                    label=""
                    name="password"
                    rules={[{required: true, message: '请输入密码!'}]}
                  >
                    <Input.Password placeholder="登录密码" prefix={<LockOutlined style={{color: 'var(--primary-color)'}}/>}
                                    size="large"/>
                  </Form.Item>


                  <div className="yph-login-page-content-form-subscription">
                    <Link to="/register">用户注册</Link>
                    <Link to="/forgotpassword">忘记密码?</Link>
                  </div>

                  <Button size="large" type="primary" htmlType="submit" block>登录</Button>
                </Form>
              </div>
            </TabPane>
            {/*<TabPane tab="微信扫码登录" key="2">*/}
            {/*  <LoginQr gotoApp={gotoApp} loginType={1}/>*/}
            {/*  <div className="yph-login-page-content-qr-sub">微信扫码安全登录</div>*/}
            {/*</TabPane>*/}

          </Tabs>
        </div>

        <div style={{margin: 'auto', textAlign: 'center', marginTop: 20}}>
          <div style={{
            transform: 'translate3d(0,-6px,0)',
            color: '#666',
            fontSize: 12,
            width: 400,
            margin: 'auto'
          }}>为保证使用体验，推荐使用谷歌浏览器、火狐浏览器以及360极速浏览器。
          </div>
          <div style={{padding: 10}}>联系客服：198 5700 5568</div>
          {/*<img style={{width: 100, height: 100}}*/}
          {/*     src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGI8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAybTJUdUlKMk9lY0QxMDAwMGcwN0IAAgQpiARjAwQAAAAA"*/}
          {/*     alt=""/>*/}
        </div>
      </div>

      <Footer/>


    </div>


  );
}

export default App;