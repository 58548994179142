import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { privateRoutes, publicRoutes } from './router';
import RouteWithSubRoutes from './utils/RoutesWithSubroutes';
import AuthWrapper from './utils/authWrapper';
import { UserContext, useUser } from "./models/user";
import { ConfigProvider, Modal } from 'antd';
import Login from './pages/login/login';
import zhCN from 'antd/lib/locale/zh_CN';
import { useEffect } from "react";
import dayjs from "dayjs";

interface Win extends Window {
  logout?: any;
}

function App() {
  const userStore = useUser();
  let win: Win = window;
  win.logout = userStore.logout;

  useEffect(() => {
    // console.log(userStore.user)
    if (userStore.user.isAuthed) userStore.getUserInfo();
  }, [userStore.user.isAuthed])

  useEffect(() => {
    let lastTime = userStore.user.last_password_reset_time
    if (lastTime === null || lastTime && dayjs().diff(lastTime, 'day') > 90) {
      Modal.warning({
        title: '警告',
        content: '您已较长时间未更换密码，为保证您的账号安全，请及时修改密码。',
      })
    }
  }, [userStore.user.last_password_reset_time])

  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <UserContext.Provider value={userStore}>
          {/** @ts-ignore */}
          <Router basename="/qiye">
            {/** @ts-ignore */}
            <Switch>
              {publicRoutes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
              {privateRoutes.map((route, i) => (
                <AuthWrapper key={i} {...route} RouterComponent={route.component} />
              ))}
              {/** @ts-ignore */}
              <Route component={Login} />
            </Switch>
          </Router>
        </UserContext.Provider>
        {/* <Modal title="公告" visible={true} width="450px" footer={null} closable={false}>
          <p>四省边际人才网停机升级通知:</p>
          <p style={{ textIndent: '2rem' }}>
            因系统升级维护，平台从2024年9月21日0时起至2024年10月8日0时，将对服务器及数据中心设备进行停机维护。请合理安排平台使用时间，给您带来的不便，敬请谅解。维护期间如有岗位需求，请联系小边
            19548009927，相关招聘信息将通过微信群等方式推送。
          </p>
          <p style={{ textIndent: '2rem' }}>
            为补偿在停机期间无法使用四省边际人才网的企业用户，所有的企业会在停机结束后免费参与平台所有新增校招活动，并且无偿获取活动简历，感谢理解支持。</p>
          <p style={{ textAlign: 'right' }}> 四省边际人才网 </p>
          <p style={{ textAlign: 'right' }}>2024年9月6日</p>
        </Modal> */}
      </div>
    </ConfigProvider >
  );
}

export default App;
