import axios from "axios";
import { message } from 'antd';
import dayjs from "dayjs";

interface Win extends Window {
  leisureCountDown?: any;
  logout?: any;
}

export type listType<T> = {
  results: Array<T>;
  count: number;

}

export function to(promise: Promise<any>) {
  return promise.then(data => {
    return [null, data]
  }).catch(err => [err])

}

function buildStringArr(params: any): Array<any> {
  let str = [];
  if (!params) {
    return [];
  }
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      if (Array.isArray(params[p])) {
        let s = [];
        let list = params[p];
        for (let i in list) {
          s.push(encodeURIComponent(list[i]));
        }
        str.push(encodeURIComponent(p) + "=" + s.join(","));
      } else {
        if (params[p] !== null)
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    }
  }

  return str;
}

export const createCookieString = (params: any) => {
  let str = buildStringArr(params);
  return str.join("&");
}

export const createParamsString = (params: any) => {
  let str = buildStringArr(params);
  return "?" + str.join("&");
};

// export const getTokenInCookie = () => {
//     let name = "token=";
//     let ca = document.cookie.split('&');
//     for (let i = 0; i < ca.length; i++) {
//         let c = ca[i].trim();
//         if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
//     }
//     return null;
// }

export const getTokenInCookie = () => {
  let name = "token=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    let item = c.split("&");
    for (let j = 0; j < item.length; j++) {
      let it = item[j];
      if (it.indexOf(name) === 0) {
        // console.log(it);
        return it.substring(name.length, it.length);
      }

    }

  }


  return null;
}

export const HOST = (() => {
  if (process.env.NODE_ENV === "development") {
    //开发调试
    // return "http://192.168.31.55:8001"
    // return "http://192.168.31.8:8006"
    // return "http://192.168.31.12:8000"
    // return "http://120.26.42.162:8000"
    // return "https://www.ssbjrcw.com"
    // return "http://192.168.31.8:8012"
  }
  //线上
  // return "https://api-test.ssbjrcw.com"
  return "https://www.ssbjrcw.com"
  // return "http://220.191.237.195";
})()

axios.defaults.baseURL = HOST + "/api"


axios.interceptors.response.use(function (response) {

  // 记录最后一次请求成功的时间
  localStorage.setItem('lastOperateDate', dayjs().format());
  let win: Win = window
  // 设置计时器 5s 触发一次
  if (win.leisureCountDown) clearTimeout(win.leisureCountDown);
  win.leisureCountDown = setTimeout(timeoutFn, (5 * 1000));

  return response;
  // 闲置超时退出登录 
  function timeoutFn() {
    win.leisureCountDown = null;
    let lastOperateDate = localStorage.getItem('lastOperateDate');
    if (win.logout && (!lastOperateDate || (dayjs().diff(lastOperateDate, 'minute') >= 30))) {
      message.error('长时间未操作，已退出登录');
      win.logout();
    } else {
      // 进入下一个循环
      win.leisureCountDown = setTimeout(timeoutFn, (5 * 1000));
    }
  }

}, function (error) {

  if (error.response) {
    if (error.response.data && error.response.status === 400) {
      message.error(error.response.data.reason);
    } else if (error.response.status === 403) {

      message.destroy('403warning')
      message.error({content:'验证失效，请退出重新登录',key:'403warning'});
    } else {
      message.destroy('serverErrorWarning')

      message.error({content:'服务器连接错误',key:'serverErrorWarning'});
    }
  }
  return Promise.reject(error);
});


export interface ReducerAction {
  type: string;
  data?: any;
}

export async function putData(url: string, data: any): Promise<any> {
  // const token = getTokenInCookie();
  return axios.put(url, data)
}


export async function putDataWithToken(url: string, data: any): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({ status: 403 });
  }
  return axios.put(url, data, { headers: { Authorization: `Bearer ${token}` } })
}

export async function postDataWithToken(url: string, data: any): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({ status: 403 });
  }
  return axios.post(url, data, { headers: { Authorization: `Bearer ${token}` } })
}

export async function getDataWithToken(url: string, params?: object): Promise<any> {
  const token = getTokenInCookie();

  if (!token) {
    return Promise.reject({ status: 403 });
  }
  let paramsString = createParamsString(params);
  return axios.get(`${url}${paramsString}`, { headers: { Authorization: `Bearer ${token}` } })
}

export async function deleteDataWithToken(url: string): Promise<any> {
  const token = getTokenInCookie();
  if (!token) {
    return Promise.reject({ status: 403 });
  }

  return axios.delete(`${url}`, { headers: { Authorization: `Bearer ${token}` } })
}


export async function getData(url: string, params?: object): Promise<any> {
  let paramsString = createParamsString(params);
  let requestUrl = `${url}${paramsString}`;

  return axios.get(requestUrl);


  //return server.get(url);
}


export async function postData(url: string, data: object): Promise<any> {
  //return axios.get()
  return axios.post(url, data)
}

