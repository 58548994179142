import asyncComponent from './utils/AsyncComponent';

const Login = asyncComponent(() => import("./pages/login/login"));

const Register = asyncComponent(() => import("./pages/login/register"));

const Company = asyncComponent(() => import("./pages/company"));

const UserCreate = asyncComponent(() => import("./pages/user/UserCreate"));

const UserHome = asyncComponent(() => import("./pages/user/UserHome"));

const CompanyCreate = asyncComponent(() => import("./pages/company/"));

const CompanyApp = asyncComponent(() => import("./pages/companyApp"));


const userBind = asyncComponent(() => import("./pages/user/userBind"));

const ForgotPassword = asyncComponent(() => import("./pages/login/forgotpassword"));

const ResetPassword = asyncComponent(() => import("./pages/login/resetPassword"));
//直播h5
const LiveVideo = asyncComponent(() => import("./pages/other/live"));

const ViewHome = asyncComponent(() => import("./pages/public/ViewHome"));

const DataVPage = asyncComponent(() => import("./pages/public/DataV"));


export const privateRoutes = [

  {
    path: '/c',
    component: Company,
    exact: true
  },
  // {
  //     path: '/user',
  //     component: UserCreate,
  //     exact: true
  // },
  {
    path: '/userbind',
    component: userBind,
    exact: true
  },
  {
    path: '/home',
    component: UserHome,
    exact: true
  },
  {
    path: '/company/:step',
    component: CompanyCreate,
  },
  {
    path: '/app/:company_id',
    component: CompanyApp
  },

];

export const publicRoutes = [
  {
    path: "/live",
    component: LiveVideo,
    exact: true
  },
  {
    path: '/',
    component: Login,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/register',
    // component: Register,
    component: UserCreate,
    exact: true
  },
  {
    path: '/forgotpassword',
    component: ForgotPassword
  },
  {
    path: '/resetpassword',
    component: ResetPassword
  },
  {
    path: '/view/dashboard',
    component: DataVPage
  },
  {
    path: '/view',
    component: ViewHome
  },

];



