 enum requestUrl{
     login='/token-auth/',
     register = '/user-registration/',
     user='/users/{user_id}/',
     binduser='/companies/bind_wechat_account/',
     companyDetail='/company',
     loginQr='/companies/get_login_qr_code_url/',
     userProfile='/user_profiles/{user_id}/',
     loginWithQr='/companies/login_status/',
     company='/companies/',
     companyLogo='/companies/{company_id}/images/',
     companyLicense='/companies/{company_id}/upload/',
     companyJobs='/companies/{company_id}/jobs/',
     companyJobPublish='/companies/{company_id}/jobs/{id}/brand/platform_list/',
     checkCompanyname='/users/check_user_identification/',
     jobResume='/companies/{company_id}/jobs/{job_id}/candidates/',
     publicResumeDetail='/companies/{company_id}/career_fairs/{career_fair_id}/resumes/{resume_id}/',
     candidates='/companies/{company_id}/candidates/',
     customCandidates='/companies/{company_id}/candidates/custom_search/',
     candidate='/companies/{company_id}/candidates/{candidate_id}/company_marked_information/',
     companyResume='/companies/{company_id}/resumes/{resume_id}/',
     publicResume='/companies/{company_id}/career_fairs/all_cf_resumes/',
     cfPubicResume='/companies/{company_id}/career_fairs/{career_fair_id}/resumes/',
     saveResume='/companies/{company_id}/career_fairs/{career_fair_id}/resumes/{resume_id}/save/',
     careerFairs='/companies/{company_id}/career_fairs/',
     cfApply='/companies/{company_id}/career_fairs/apply/',
     channels='/message_board/jobs/channel_list/',
     messages='/message_board/jobs/get_message_list/{channel_id}/',
     team='/companies/{company_id}/accounts/',
     cfRitui='companies/{company_id}/career_fairs/{career_fair_id}/applied_ritui_list/',
     cfjobs='/companies/{company_id}/jobs/career_fairs/{career_fair_id}/applied_list/',
     addJobToCf='/companies/{company_id}/jobs/apply/',
     deleteJobFromCf='/companies/{company_id}/jobs/{job_id}/remove_cf/career_fair/{career_fair_id}/',
     labels='/companies/{company_id}/resume_lable_list/',
     interview='/companies/{company_id}/candidates/{candidate_id}/events/',
     channelEvents='/companies/{company_id}/new_events/',
     activateInterview='/companies/{company_id}/candidates/{candidate_id}/events/{event_id}/activate/',
     recentVisitCount='/companies/{company_id}/recent_visit_count/',
     recentCandidateCount= '/companies/{company_id}/recent_candidate_count/',
     recentCareerFairInformation='/companies/{company_id}/recent_career_fair_information/',
     memberStatusList='/companies/{company_id}/circle_status_list/',
     becomeMember='/circles/{circle_id}/companies/{company_id}/projects/',
     becomeMemberApply='/circles/companies/{company_id}/apply_association/',
     RenewalMember=`/circles/companies/{global_company_id}/renewals_form_list/`,
     publicUpload = `/public/upload/image/`,
     examStudents = `/companies/{company_id}/career_fairs/{career_fair_id}/applied_examinee/`,
     checkStudents = `/companies/{company_id}/applied_examinee/{id}/check/`,
     saveResumeScore=`/companies/{company_id}/company_comment_user/{creator_id}/`,
     bulletinBoardList=`/companies/{company_id}/company_notice_list/`,
     signInBulletin = `/companies/{company_id}/company_lookup_notice/{notice_id}/`,
    setUserActiveCount = `/users/set_user_active_count/`,
    exams=`companies/{company_id}/examination_list/`,
    startExams=`companies/{company_id}/examination_list/{exam_id}/send_examination_invitation/`,
    examsDetail=`/companies/{company_id}/examination_list/{examination_id}/`,
    exportCanidateExamination=`/companies/{company_id}/export_examination_excel/`,//GET,params:{user_id,job_id},
    // uploadExcel=`/companies/{company_id}/parse_examination_excel/`,
	schoolList = `/public/get_school_list/`,
    jobExams = `/companies/{company_id}/jobs/{job_id}/apply_exam_info_list/`,
    openExam = `/companies/{company_id}/open_exam/`,
    arrangeTest = `/companies/{company_id}/jobs/{job_id}/apply_exam_info_detail/`,
    uploadExamFile= `/companies/{company_id}/parse_examination_excel/`,
    candidateExport = `/companies/{company_id}/candidates/custom_candidate_export/`,
    candidateReport = `/companies/{company_id}/company_candidate_report/`,
    
     //日程记录接口
     events = '/companies/{company_id}/new_events/',

 };

export default requestUrl;